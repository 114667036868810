import client from '../../framework/rest/client';
import { useQuery, queryOptions } from '@tanstack/react-query';
import type {
  GetApplicationQueryResponse,
  GetApplicationHeaderParams,
  GetApplication404,
  GetApplication500,
} from '../models/GetApplication';
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';

type GetApplicationClient = typeof client<
  GetApplicationQueryResponse,
  GetApplication404 | GetApplication500,
  never
>;
type GetApplication = {
  data: GetApplicationQueryResponse;
  error: GetApplication404 | GetApplication500;
  request: never;
  pathParams: never;
  queryParams: never;
  headerParams: GetApplicationHeaderParams;
  response: GetApplicationQueryResponse;
  client: {
    parameters: Partial<Parameters<GetApplicationClient>[0]>;
    return: Awaited<ReturnType<GetApplicationClient>>;
  };
};
export const getApplicationQueryKey = () => [{ url: '/application/' }] as const;
export type GetApplicationQueryKey = ReturnType<typeof getApplicationQueryKey>;
export function getApplicationQueryOptions(
  headers?: GetApplication['headerParams'],
  options: GetApplication['client']['parameters'] = {}
) {
  const queryKey = getApplicationQueryKey();
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<GetApplication['data'], GetApplication['error']>(
        {
          method: 'get',
          url: `/application/`,
          headers: { ...headers, ...options.headers },
          ...options,
        }
      );
      return res.data;
    },
  });
}
/**
 * @description get current application
 * @link /application/ */
export function useGetApplication<
  TData = GetApplication['response'],
  TQueryData = GetApplication['response'],
  TQueryKey extends QueryKey = GetApplicationQueryKey
>(
  headers?: GetApplication['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApplication['response'],
        GetApplication['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: GetApplication['client']['parameters'];
  } = {}
): UseQueryResult<TData, GetApplication['error']> & {
  queryKey: TQueryKey;
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApplicationQueryKey();
  const query = useQuery({
    ...(getApplicationQueryOptions(
      headers,
      clientOptions
    ) as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetApplication['error']> & {
    queryKey: TQueryKey;
  };
  query.queryKey = queryKey as TQueryKey;
  return query;
}

import client from '../../framework/rest/client';
import { useQuery, queryOptions } from '@tanstack/react-query';
import type {
  GetApplicationResultQueryResponse,
  GetApplicationResultHeaderParams,
  GetApplicationResult404,
  GetApplicationResult500,
} from '../models/GetApplicationResult';
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';

type GetApplicationResultClient = typeof client<
  GetApplicationResultQueryResponse,
  GetApplicationResult404 | GetApplicationResult500,
  never
>;
type GetApplicationResult = {
  data: GetApplicationResultQueryResponse;
  error: GetApplicationResult404 | GetApplicationResult500;
  request: never;
  pathParams: never;
  queryParams: never;
  headerParams: GetApplicationResultHeaderParams;
  response: GetApplicationResultQueryResponse;
  client: {
    parameters: Partial<Parameters<GetApplicationResultClient>[0]>;
    return: Awaited<ReturnType<GetApplicationResultClient>>;
  };
};
export const getApplicationResultQueryKey = () =>
  [{ url: '/application/result' }] as const;
export type GetApplicationResultQueryKey = ReturnType<
  typeof getApplicationResultQueryKey
>;
export function getApplicationResultQueryOptions(
  headers?: GetApplicationResult['headerParams'],
  options: GetApplicationResult['client']['parameters'] = {}
) {
  const queryKey = getApplicationResultQueryKey();
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetApplicationResult['data'],
        GetApplicationResult['error']
      >({
        method: 'get',
        url: `/application/result`,
        headers: { ...headers, ...options.headers },
        ...options,
      });
      return res.data;
    },
  });
}
/**
 * @link /application/result */
export function useGetApplicationResult<
  TData = GetApplicationResult['response'],
  TQueryData = GetApplicationResult['response'],
  TQueryKey extends QueryKey = GetApplicationResultQueryKey
>(
  headers?: GetApplicationResult['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApplicationResult['response'],
        GetApplicationResult['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: GetApplicationResult['client']['parameters'];
  } = {}
): UseQueryResult<TData, GetApplicationResult['error']> & {
  queryKey: TQueryKey;
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApplicationResultQueryKey();
  const query = useQuery({
    ...(getApplicationResultQueryOptions(
      headers,
      clientOptions
    ) as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetApplicationResult['error']> & {
    queryKey: TQueryKey;
  };
  query.queryKey = queryKey as TQueryKey;
  return query;
}

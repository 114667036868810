import client from '../../framework/rest/client';
import { useMutation } from '@tanstack/react-query';
import type {
  DeleteApplicationMutationResponse,
  DeleteApplicationHeaderParams,
  DeleteApplication404,
  DeleteApplication500,
} from '../models/DeleteApplication';
import type { UseMutationOptions } from '@tanstack/react-query';

type DeleteApplicationClient = typeof client<
  DeleteApplicationMutationResponse,
  DeleteApplication404 | DeleteApplication500,
  never
>;
type DeleteApplication = {
  data: DeleteApplicationMutationResponse;
  error: DeleteApplication404 | DeleteApplication500;
  request: never;
  pathParams: never;
  queryParams: never;
  headerParams: DeleteApplicationHeaderParams;
  response: DeleteApplicationMutationResponse;
  client: {
    parameters: Partial<Parameters<DeleteApplicationClient>[0]>;
    return: Awaited<ReturnType<DeleteApplicationClient>>;
  };
};
/**
 * @description delete current application
 * @link /application/ */
export function useDeleteApplication(
  headers?: DeleteApplication['headerParams'],
  options: {
    mutation?: UseMutationOptions<
      DeleteApplication['response'],
      DeleteApplication['error'],
      void
    >;
    client?: DeleteApplication['client']['parameters'];
  } = {}
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {};
  return useMutation({
    mutationFn: async () => {
      const res = await client<
        DeleteApplication['data'],
        DeleteApplication['error'],
        void
      >({
        method: 'delete',
        url: `/application/`,
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions,
      });
      return res.data;
    },
    ...mutationOptions,
  });
}

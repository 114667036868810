import client from '../../framework/rest/client';
import { useQuery, queryOptions } from '@tanstack/react-query';
import type {
  GetOptionsQueryResponse,
  GetOptions500,
} from '../models/GetOptions';
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';

type GetOptionsClient = typeof client<
  GetOptionsQueryResponse,
  GetOptions500,
  never
>;
type GetOptions = {
  data: GetOptionsQueryResponse;
  error: GetOptions500;
  request: never;
  pathParams: never;
  queryParams: never;
  headerParams: never;
  response: GetOptionsQueryResponse;
  client: {
    parameters: Partial<Parameters<GetOptionsClient>[0]>;
    return: Awaited<ReturnType<GetOptionsClient>>;
  };
};
export const getOptionsQueryKey = () => [{ url: '/options/' }] as const;
export type GetOptionsQueryKey = ReturnType<typeof getOptionsQueryKey>;
export function getOptionsQueryOptions(
  options: GetOptions['client']['parameters'] = {}
) {
  const queryKey = getOptionsQueryKey();
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<GetOptions['data'], GetOptions['error']>({
        method: 'get',
        url: `/options/`,
        ...options,
      });
      return res.data;
    },
  });
}
/**
 * @description get options used for inputs
 * @link /options/ */
export function useGetOptions<
  TData = GetOptions['response'],
  TQueryData = GetOptions['response'],
  TQueryKey extends QueryKey = GetOptionsQueryKey
>(
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetOptions['response'],
        GetOptions['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: GetOptions['client']['parameters'];
  } = {}
): UseQueryResult<TData, GetOptions['error']> & {
  queryKey: TQueryKey;
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getOptionsQueryKey();
  const query = useQuery({
    ...(getOptionsQueryOptions(clientOptions) as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetOptions['error']> & {
    queryKey: TQueryKey;
  };
  query.queryKey = queryKey as TQueryKey;
  return query;
}

import { Divider, Flex, LunarLogo, Text } from '@lunarway/matter-web';
import styled, { css } from 'styled-components';

export const ViewFooter = () => (
  <StyledFooter flexDirection="column">
    <Divider />
    <StyledFooterInner gap={1200} alignSelf="center" alignItems="center">
      <LunarLogo size="81px" />
      <Flex flexDirection="column" gap={100}>
        <Text fontWeight="medium">
          We&apos;re here to answer your questions.
        </Text>
        <Text variant="secondary">
          We&apos;re on standby to answer any questions you may have. Please
          call us for free at 430-49309-9430 or get in touch through
          support@lunar.app.
        </Text>
      </Flex>
    </StyledFooterInner>
  </StyledFooter>
);

const StyledFooter = styled(Flex)(
  ({ theme }) => css`
    width: 100%;
    padding: 0 ${theme.tokens.spaces[500]};
    background-color: ${theme.backgroundPrimary};

    @media screen and (max-width: 768px) {
      padding: 0;
    }
  `
);

const StyledFooterInner = styled(Flex)(
  ({ theme }) => css`
    padding: ${theme.tokens.spaces[500]} ${theme.tokens.spaces[200]};
    max-width: 600px;

    @media screen and (max-width: 768px) {
      gap: ${theme.tokens.spaces[500]};
      padding: 0;
      flex-direction: column;
      align-items: flex-start;
      padding: ${theme.tokens.spaces[500]};
    }
  `
);

export const IdentityResultCode = {
  VERIFIED: 'VERIFIED',
  VERIFIED_SHOW_RESULT: 'VERIFIED_SHOW_RESULT',
  COOLDOWN: 'COOLDOWN',
  CREDIT_BLOCKED: 'CREDIT_BLOCKED',
} as const;
export type IdentityResultCode =
  (typeof IdentityResultCode)[keyof typeof IdentityResultCode];
export type IdentityResult = {
  /**
   * @type string
   */
  code: IdentityResultCode;
  /**
   * @description the date is present if the code is 'COOLDOWN'
   * @type string | undefined date
   * @example Mon Mar 11 2024 09:39:53 GMT+0100 (Central European Standard Time)
   */
  cooldownUntilDate?: string;
};

import client from '../../framework/rest/client';
import { useQuery, queryOptions } from '@tanstack/react-query';
import type {
  GetApplicationStatusQueryResponse,
  GetApplicationStatusHeaderParams,
  GetApplicationStatus404,
  GetApplicationStatus500,
} from '../models/GetApplicationStatus';
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';

type GetApplicationStatusClient = typeof client<
  GetApplicationStatusQueryResponse,
  GetApplicationStatus404 | GetApplicationStatus500,
  never
>;
type GetApplicationStatus = {
  data: GetApplicationStatusQueryResponse;
  error: GetApplicationStatus404 | GetApplicationStatus500;
  request: never;
  pathParams: never;
  queryParams: never;
  headerParams: GetApplicationStatusHeaderParams;
  response: GetApplicationStatusQueryResponse;
  client: {
    parameters: Partial<Parameters<GetApplicationStatusClient>[0]>;
    return: Awaited<ReturnType<GetApplicationStatusClient>>;
  };
};
export const getApplicationStatusQueryKey = () =>
  [{ url: '/application/status' }] as const;
export type GetApplicationStatusQueryKey = ReturnType<
  typeof getApplicationStatusQueryKey
>;
export function getApplicationStatusQueryOptions(
  headers?: GetApplicationStatus['headerParams'],
  options: GetApplicationStatus['client']['parameters'] = {}
) {
  const queryKey = getApplicationStatusQueryKey();
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetApplicationStatus['data'],
        GetApplicationStatus['error']
      >({
        method: 'get',
        url: `/application/status`,
        headers: { ...headers, ...options.headers },
        ...options,
      });
      return res.data;
    },
  });
}
/**
 * @link /application/status */
export function useGetApplicationStatus<
  TData = GetApplicationStatus['response'],
  TQueryData = GetApplicationStatus['response'],
  TQueryKey extends QueryKey = GetApplicationStatusQueryKey
>(
  headers?: GetApplicationStatus['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApplicationStatus['response'],
        GetApplicationStatus['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: GetApplicationStatus['client']['parameters'];
  } = {}
): UseQueryResult<TData, GetApplicationStatus['error']> & {
  queryKey: TQueryKey;
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApplicationStatusQueryKey();
  const query = useQuery({
    ...(getApplicationStatusQueryOptions(
      headers,
      clientOptions
    ) as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetApplicationStatus['error']> & {
    queryKey: TQueryKey;
  };
  query.queryKey = queryKey as TQueryKey;
  return query;
}

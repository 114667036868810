import client from '../../framework/rest/client';
import { useMutation } from '@tanstack/react-query';
import type {
  SignOutMutationResponse,
  SignOutHeaderParams,
  SignOut500,
} from '../models/SignOut';
import type { UseMutationOptions } from '@tanstack/react-query';

type SignOutClient = typeof client<SignOutMutationResponse, SignOut500, never>;
type SignOut = {
  data: SignOutMutationResponse;
  error: SignOut500;
  request: never;
  pathParams: never;
  queryParams: never;
  headerParams: SignOutHeaderParams;
  response: SignOutMutationResponse;
  client: {
    parameters: Partial<Parameters<SignOutClient>[0]>;
    return: Awaited<ReturnType<SignOutClient>>;
  };
};
/**
 * @description sign out of application without deleting the application
 * @link /application/signout */
export function useSignOut(
  headers?: SignOut['headerParams'],
  options: {
    mutation?: UseMutationOptions<SignOut['response'], SignOut['error'], void>;
    client?: SignOut['client']['parameters'];
  } = {}
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {};
  return useMutation({
    mutationFn: async () => {
      const res = await client<SignOut['data'], SignOut['error'], void>({
        method: 'post',
        url: `/application/signout`,
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions,
      });
      return res.data;
    },
    ...mutationOptions,
  });
}

import client from '../../framework/rest/client';
import { useMutation } from '@tanstack/react-query';
import type {
  PostApplicationMutationResponse,
  PostApplicationHeaderParams,
  PostApplication404,
  PostApplication500,
} from '../models/PostApplication';
import type { UseMutationOptions } from '@tanstack/react-query';

type PostApplicationClient = typeof client<
  PostApplicationMutationResponse,
  PostApplication404 | PostApplication500,
  never
>;
type PostApplication = {
  data: PostApplicationMutationResponse;
  error: PostApplication404 | PostApplication500;
  request: never;
  pathParams: never;
  queryParams: never;
  headerParams: PostApplicationHeaderParams;
  response: PostApplicationMutationResponse;
  client: {
    parameters: Partial<Parameters<PostApplicationClient>[0]>;
    return: Awaited<ReturnType<PostApplicationClient>>;
  };
};
/**
 * @description create new application
 * @link /application/ */
export function usePostApplication(
  headers?: PostApplication['headerParams'],
  options: {
    mutation?: UseMutationOptions<
      PostApplication['response'],
      PostApplication['error'],
      void
    >;
    client?: PostApplication['client']['parameters'];
  } = {}
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {};
  return useMutation({
    mutationFn: async () => {
      const res = await client<
        PostApplication['data'],
        PostApplication['error'],
        void
      >({
        method: 'post',
        url: `/application/`,
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions,
      });
      return res.data;
    },
    ...mutationOptions,
  });
}

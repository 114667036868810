import client from '../../framework/rest/client';
import { useMutation } from '@tanstack/react-query';
import type {
  SubmitApplicationMutationResponse,
  SubmitApplicationHeaderParams,
  SubmitApplication400,
  SubmitApplication404,
  SubmitApplication500,
} from '../models/SubmitApplication';
import type { UseMutationOptions } from '@tanstack/react-query';

type SubmitApplicationClient = typeof client<
  SubmitApplicationMutationResponse,
  SubmitApplication400 | SubmitApplication404 | SubmitApplication500,
  never
>;
type SubmitApplication = {
  data: SubmitApplicationMutationResponse;
  error: SubmitApplication400 | SubmitApplication404 | SubmitApplication500;
  request: never;
  pathParams: never;
  queryParams: never;
  headerParams: SubmitApplicationHeaderParams;
  response: SubmitApplicationMutationResponse;
  client: {
    parameters: Partial<Parameters<SubmitApplicationClient>[0]>;
    return: Awaited<ReturnType<SubmitApplicationClient>>;
  };
};
/**
 * @description submit application  for review
 * @link /application/submit */
export function useSubmitApplication(
  headers?: SubmitApplication['headerParams'],
  options: {
    mutation?: UseMutationOptions<
      SubmitApplication['response'],
      SubmitApplication['error'],
      void
    >;
    client?: SubmitApplication['client']['parameters'];
  } = {}
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {};
  return useMutation({
    mutationFn: async () => {
      const res = await client<
        SubmitApplication['data'],
        SubmitApplication['error'],
        void
      >({
        method: 'post',
        url: `/application/submit`,
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions,
      });
      return res.data;
    },
    ...mutationOptions,
  });
}

import client from '../../framework/rest/client';
import { useMutation } from '@tanstack/react-query';
import type {
  IdentityVerifiedMutationResponse,
  IdentityVerifiedHeaderParams,
  IdentityVerified400,
  IdentityVerified404,
  IdentityVerified500,
} from '../models/IdentityVerified';
import type { UseMutationOptions } from '@tanstack/react-query';

type IdentityVerifiedClient = typeof client<
  IdentityVerifiedMutationResponse,
  IdentityVerified400 | IdentityVerified404 | IdentityVerified500,
  never
>;
type IdentityVerified = {
  data: IdentityVerifiedMutationResponse;
  error: IdentityVerified400 | IdentityVerified404 | IdentityVerified500;
  request: never;
  pathParams: never;
  queryParams: never;
  headerParams: IdentityVerifiedHeaderParams;
  response: IdentityVerifiedMutationResponse;
  client: {
    parameters: Partial<Parameters<IdentityVerifiedClient>[0]>;
    return: Awaited<ReturnType<IdentityVerifiedClient>>;
  };
};
/**
 * @description identity verified (mitid) connect lunar
 * @link /application/connect/identity */
export function useIdentityVerified(
  headers?: IdentityVerified['headerParams'],
  options: {
    mutation?: UseMutationOptions<
      IdentityVerified['response'],
      IdentityVerified['error'],
      void
    >;
    client?: IdentityVerified['client']['parameters'];
  } = {}
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {};
  return useMutation({
    mutationFn: async () => {
      const res = await client<
        IdentityVerified['data'],
        IdentityVerified['error'],
        void
      >({
        method: 'post',
        url: `/application/connect/identity`,
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions,
      });
      return res.data;
    },
    ...mutationOptions,
  });
}

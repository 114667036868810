import client from '../../framework/rest/client';
import { useMutation } from '@tanstack/react-query';
import type {
  InitiatePsd2ConsentMutationResponse,
  InitiatePsd2ConsentHeaderParams,
  InitiatePsd2Consent400,
  InitiatePsd2Consent500,
} from '../models/InitiatePsd2Consent';
import type { UseMutationOptions } from '@tanstack/react-query';

type InitiatePsd2ConsentClient = typeof client<
  InitiatePsd2ConsentMutationResponse,
  InitiatePsd2Consent400 | InitiatePsd2Consent500,
  never
>;
type InitiatePsd2Consent = {
  data: InitiatePsd2ConsentMutationResponse;
  error: InitiatePsd2Consent400 | InitiatePsd2Consent500;
  request: never;
  pathParams: never;
  queryParams: never;
  headerParams: InitiatePsd2ConsentHeaderParams;
  response: InitiatePsd2ConsentMutationResponse;
  client: {
    parameters: Partial<Parameters<InitiatePsd2ConsentClient>[0]>;
    return: Awaited<ReturnType<InitiatePsd2ConsentClient>>;
  };
};
/**
 * @description Create a PSD2 consent URL
 * @link /application/connect/psd2 */
export function useInitiatePsd2Consent(
  headers?: InitiatePsd2Consent['headerParams'],
  options: {
    mutation?: UseMutationOptions<
      InitiatePsd2Consent['response'],
      InitiatePsd2Consent['error'],
      void
    >;
    client?: InitiatePsd2Consent['client']['parameters'];
  } = {}
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {};
  return useMutation({
    mutationFn: async () => {
      const res = await client<
        InitiatePsd2Consent['data'],
        InitiatePsd2Consent['error'],
        void
      >({
        method: 'post',
        url: `/application/connect/psd2`,
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions,
      });
      return res.data;
    },
    ...mutationOptions,
  });
}

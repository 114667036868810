import client from '../../framework/rest/client';
import { useMutation } from '@tanstack/react-query';
import type {
  InitiateEskatConsentMutationResponse,
  InitiateEskatConsentHeaderParams,
  InitiateEskatConsent400,
  InitiateEskatConsent404,
  InitiateEskatConsent500,
} from '../models/InitiateEskatConsent';
import type { UseMutationOptions } from '@tanstack/react-query';

type InitiateEskatConsentClient = typeof client<
  InitiateEskatConsentMutationResponse,
  InitiateEskatConsent400 | InitiateEskatConsent404 | InitiateEskatConsent500,
  never
>;
type InitiateEskatConsent = {
  data: InitiateEskatConsentMutationResponse;
  error:
    | InitiateEskatConsent400
    | InitiateEskatConsent404
    | InitiateEskatConsent500;
  request: never;
  pathParams: never;
  queryParams: never;
  headerParams: InitiateEskatConsentHeaderParams;
  response: InitiateEskatConsentMutationResponse;
  client: {
    parameters: Partial<Parameters<InitiateEskatConsentClient>[0]>;
    return: Awaited<ReturnType<InitiateEskatConsentClient>>;
  };
};
/**
 * @description Create a eskat consent URL
 * @link /application/connect/eskat */
export function useInitiateEskatConsent(
  headers?: InitiateEskatConsent['headerParams'],
  options: {
    mutation?: UseMutationOptions<
      InitiateEskatConsent['response'],
      InitiateEskatConsent['error'],
      void
    >;
    client?: InitiateEskatConsent['client']['parameters'];
  } = {}
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {};
  return useMutation({
    mutationFn: async () => {
      const res = await client<
        InitiateEskatConsent['data'],
        InitiateEskatConsent['error'],
        void
      >({
        method: 'post',
        url: `/application/connect/eskat`,
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions,
      });
      return res.data;
    },
    ...mutationOptions,
  });
}

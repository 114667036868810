import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Flex } from '@lunarway/matter-web';

import { ViewFooter } from './ViewFooter';

interface ViewProps {
  children: ReactNode;
  actions?: ReactNode;
}

export const View = ({ children, actions }: ViewProps) => {
  const hasActions = !!actions;

  return (
    <StyledView flexDirection="column" alignItems="center">
      <StyledViewMain
        flex={1}
        flexDirection="column"
        justifyContent="center"
        $hasActions={hasActions}
      >
        {hasActions && <StyledActions>{actions}</StyledActions>}
        {children}
      </StyledViewMain>
      <ViewFooter />
    </StyledView>
  );
};

const StyledView = styled(Flex)(
  ({ theme }) => css`
    height: 100vh;
    padding-block-start: ${theme.tokens.spaces[300]};
  `
);

const StyledViewMain = styled(Flex)<{ $hasActions: boolean }>(
  ({ $hasActions, theme }) => css`
    max-width: 600px;
    width: 100%;
    box-sizing: border-box;
    padding: ${theme.tokens.spaces[900]} ${theme.tokens.spaces[200]};

    ${$hasActions &&
    css`
      padding-block-start: ${theme.tokens.spaces[500]};

      ${StyledActions} {
        padding-block-end: ${theme.tokens.spaces[500]};
      }
    `}
  `
);

const StyledActions = styled(Flex)``;

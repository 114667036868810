import type { Url } from './Url';

export const InitiateEskatConsent400Code = {
  eskatConsentUrl: 'eskatConsentUrl',
} as const;
export type InitiateEskatConsent400Code =
  (typeof InitiateEskatConsent400Code)[keyof typeof InitiateEskatConsent400Code];
/**
 * @description verification failed
 */
export type InitiateEskatConsent400 = {
  /**
   * @type string
   */
  code: InitiateEskatConsent400Code;
};

/**
 * @description Not found
 */
export type InitiateEskatConsent404 = any | null;

/**
 * @description Internal server error
 */
export type InitiateEskatConsent500 = any | null;

export type InitiateEskatConsentHeaderParams =
  | {
      /**
       * @description Session cookie
       * @type string | undefined foo=bar
       */
      Cookie?: string;
    }
  | undefined;

/**
 * @description created
 */
export type InitiateEskatConsent201 = Url;

/**
 * @description created
 */
export type InitiateEskatConsentMutationResponse = Url;
export type InitiateEskatConsentMutation = {
  Response: InitiateEskatConsentMutationResponse;
  HeaderParams: InitiateEskatConsentHeaderParams;
  Errors:
    | InitiateEskatConsent400
    | InitiateEskatConsent404
    | InitiateEskatConsent500;
};

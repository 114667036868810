import type { Url } from './Url';

export const InitiatePsd2Consent400Code = {
  psd2ConsentUrl: 'psd2ConsentUrl',
} as const;
export type InitiatePsd2Consent400Code =
  (typeof InitiatePsd2Consent400Code)[keyof typeof InitiatePsd2Consent400Code];
/**
 * @description PSD2 failed
 */
export type InitiatePsd2Consent400 = {
  /**
   * @type string
   */
  code: InitiatePsd2Consent400Code;
};

/**
 * @description Internal server error
 */
export type InitiatePsd2Consent500 = any | null;

export type InitiatePsd2ConsentHeaderParams =
  | {
      /**
       * @description Session cookie
       * @type string | undefined foo=bar
       */
      Cookie?: string;
    }
  | undefined;

/**
 * @description created
 */
export type InitiatePsd2Consent201 = Url;

/**
 * @description created
 */
export type InitiatePsd2ConsentMutationResponse = Url;
export type InitiatePsd2ConsentMutation = {
  Response: InitiatePsd2ConsentMutationResponse;
  HeaderParams: InitiatePsd2ConsentHeaderParams;
  Errors: InitiatePsd2Consent400 | InitiatePsd2Consent500;
};

import client from '../../framework/rest/client';
import { useMutation } from '@tanstack/react-query';
import type {
  PutApplicationEmailMutationRequest,
  PutApplicationEmailMutationResponse,
  PutApplicationEmailHeaderParams,
  PutApplicationEmail404,
  PutApplicationEmail500,
} from '../models/PutApplicationEmail';
import type { UseMutationOptions } from '@tanstack/react-query';

type PutApplicationEmailClient = typeof client<
  PutApplicationEmailMutationResponse,
  PutApplicationEmail404 | PutApplicationEmail500,
  PutApplicationEmailMutationRequest
>;
type PutApplicationEmail = {
  data: PutApplicationEmailMutationResponse;
  error: PutApplicationEmail404 | PutApplicationEmail500;
  request: PutApplicationEmailMutationRequest;
  pathParams: never;
  queryParams: never;
  headerParams: PutApplicationEmailHeaderParams;
  response: PutApplicationEmailMutationResponse;
  client: {
    parameters: Partial<Parameters<PutApplicationEmailClient>[0]>;
    return: Awaited<ReturnType<PutApplicationEmailClient>>;
  };
};
/**
 * @description add email to application
 * @link /application/email */
export function usePutApplicationEmail(
  headers?: PutApplicationEmail['headerParams'],
  options: {
    mutation?: UseMutationOptions<
      PutApplicationEmail['response'],
      PutApplicationEmail['error'],
      PutApplicationEmail['request']
    >;
    client?: PutApplicationEmail['client']['parameters'];
  } = {}
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {};
  return useMutation({
    mutationFn: async (data) => {
      const res = await client<
        PutApplicationEmail['data'],
        PutApplicationEmail['error'],
        PutApplicationEmail['request']
      >({
        method: 'put',
        url: `/application/email`,
        data,
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions,
      });
      return res.data;
    },
    ...mutationOptions,
  });
}

import client from '../../framework/rest/client';
import { useMutation } from '@tanstack/react-query';
import type {
  AddUserDataMutationRequest,
  AddUserDataMutationResponse,
  AddUserDataHeaderParams,
  AddUserData400,
  AddUserData404,
  AddUserData500,
} from '../models/AddUserData';
import type { UseMutationOptions } from '@tanstack/react-query';

type AddUserDataClient = typeof client<
  AddUserDataMutationResponse,
  AddUserData400 | AddUserData404 | AddUserData500,
  AddUserDataMutationRequest
>;
type AddUserData = {
  data: AddUserDataMutationResponse;
  error: AddUserData400 | AddUserData404 | AddUserData500;
  request: AddUserDataMutationRequest;
  pathParams: never;
  queryParams: never;
  headerParams: AddUserDataHeaderParams;
  response: AddUserDataMutationResponse;
  client: {
    parameters: Partial<Parameters<AddUserDataClient>[0]>;
    return: Awaited<ReturnType<AddUserDataClient>>;
  };
};
/**
 * @description update application user input
 * @link /application/userdata */
export function useAddUserData(
  headers?: AddUserData['headerParams'],
  options: {
    mutation?: UseMutationOptions<
      AddUserData['response'],
      AddUserData['error'],
      AddUserData['request']
    >;
    client?: AddUserData['client']['parameters'];
  } = {}
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {};
  return useMutation({
    mutationFn: async (data) => {
      const res = await client<
        AddUserData['data'],
        AddUserData['error'],
        AddUserData['request']
      >({
        method: 'put',
        url: `/application/userdata`,
        data,
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions,
      });
      return res.data;
    },
    ...mutationOptions,
  });
}
